import React from "react";
import question from "../assets/images/questions.svg";
import ExpectComp from "../components/ExpectComp";
import book from "../assets/icons/Book And Pencil.svg";
import manufacture from "../assets/icons/Manufacturing.svg";
import management from "../assets/icons/Management.svg";
import Euro from "../assets/icons/Euro Money.svg";
import Idea from "../assets/icons/Idea Sharing.svg";

const WhatToExpect = () => {
  return (
    <div className="spacings text-white  pt-[0px] pb-[0px] md:pb-[180px]">
      <div className="flex items-center justify-center w-full mb-[40px] md:mb-[110px]">
        <h1 className="font-[900] text-[25px] md:text-[35px] lg:text-[50px] leading-[40px] lg:leading-[65px] text-center ">
          What to Expect
        </h1>
      </div>

      <div className="flex md:flex-row flex-col gap-8 md:gap-8 items-center">
        <div className="w-full md:w-[50%]">
          <div className="flex flex-col gap-4">
            <ExpectComp
              title="Founders' Stories"
              description=" Inspiring stories from successful entrepreneurs"
              img={book}
            />
            <ExpectComp
              title="Industry Insight"
              description="Expert-led sessions on issues around innovation, growth and funding"
              img={manufacture}
            />

            <ExpectComp
              title="Funding Opportunity"
              description="Connect with investors and explore financing options"
              img={Euro}
            />
            <ExpectComp
              title="Startup Pitch"
              description="Showcase your idea to secure funding or grants"
              img={Idea}
            />
            <ExpectComp
              title="Networking"
              description="Build meaningful relationships with fellow founders, investors, and experts"
              img={management}
            />
          </div>
        </div>
        <div className="w-full md:w-[50%] flex justify-end items-center">
          <img src={question} alt="question" className="" />
        </div>
      </div>
    </div>
  );
};

export default WhatToExpect;
