import React from "react";
import hero from "../assets/images/hero2.svg";

const Hero = () => {
  return (
    <div className="spacings">
      <div className="flex md:flex-row flex-col gap-4 items-center pt-[40px] md:pt-[150px]">
        <div className=" w-full md:w-[60%] lg:w-[70%] ">
          <div className="">
            <h1 class="">
              <span className="gradient_text text-[30px] md:text-[38px] lg:text-[48px]">
                Empowering
              </span>{" "}
              <span className="gradient_text text-[30px] md:text-[38px] lg:text-[48px]">
                Entrepreneurs,
              </span>{" "}
              <br />{" "}
              <span className="gradient_text text-[35px] md:text-[45px] lg:text-[60px]">
                Fueling
              </span>{" "}
              {"  "} {"  "}
              <span className="gradient_text text-[35px] md:text-[45px] lg:text-[60px]">
                Growth
              </span>
            </h1>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLSesC5FhbR3Pt2TrnTKtDH-gRe94uUiDcmuzN5RRYIsFCckORw/viewform"
            >
              <button className="bg-white mt-9 text-primary rounded-[10px] px-[14px] font-semibold w-fit h-[42px] text-sm md:text-base leading-4">
                Join the Movement
              </button>
            </a>
          </div>
        </div>
        <div className="w-full md:w-[40%] lg:w-[30%] mt-6 md:mt-0">
          <img src={hero} alt="Hero" className="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
