import React from "react";

const Button = ({ children, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="btn_bg rounded-[10px] px-[14px] font-semibold w-fit h-[42px] text-white text-sm md:text-base leading-4"
    >
      {children}
    </button>
  );
};

export default Button;
