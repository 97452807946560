import React, { useState } from "react";
import logo from "../assets/images/logo.svg";
import Button from "./Button";
import { Close, Menu } from "@mui/icons-material";

const Nav = () => {
  const [toggleMenu, setToggleMenu] = useState("");
  return (
    <div className="relative">
      <div
        onClick={() => setToggleMenu(!toggleMenu)}
        className="flex justify-between w-full bg-transparent  items-center spacings py-5"
      >
        <div className="">
          <img src={logo} alt="Logo" className="" />
        </div>
        <div className="block md:hidden">
          <Menu style={{ color: "#FFF", fontSize: "23px" }} />
        </div>
        <div className="hidden md:block">
          <ul className="flex gap-[72px] md:gap-[32px] lg:gap-[72px] items-center text-white cursor-pointer">
            <a href="#about" className="decoration-[none]">
              <p className="font-semibold text-base leading-4 ">About</p>
            </a>
            {/* <a href="/"> */}
            <p className="font-semibold text-base leading-4 ">Event</p>
            {/* </a> */}
            <a href="#patners">
              <p className="font-semibold text-base leading-4 ">Partners</p>
            </a>
          </ul>
        </div>
        <div className="hidden md:block">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLSesC5FhbR3Pt2TrnTKtDH-gRe94uUiDcmuzN5RRYIsFCckORw/viewform"
          >
            <Button>Join Us</Button>
          </a>
        </div>
      </div>

      {toggleMenu && (
        <div className="block md:hidden fixed top-0 z-[40] h-screen overflow-hidden bg-[#17171a] w-full">
          <div
            className="flex justify-end items-center py-8 pr-[30px]"
            onClick={() => setToggleMenu(false)}
          >
            <Close style={{ color: "#FFF", fontSize: "23px" }} />
          </div>
          <div className="pt-[40px] ">
            <ul
              onClick={() => setToggleMenu(false)}
              className="flex flex-col gap-[72px] md:gap-[32px] lg:gap-[72px] items-center text-white cursor-pointer"
            >
              <a href="#about" className="decoration-[none]">
                <p className="font-semibold text-base leading-4 ">About</p>
              </a>
              {/* <a href="/"> */}
              <p className="font-semibold text-base leading-4 ">Event</p>
              {/* </a> */}
              <a href="#patners">
                <p className="font-semibold text-base leading-4 ">Partners</p>
              </a>
              <div className="">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSesC5FhbR3Pt2TrnTKtDH-gRe94uUiDcmuzN5RRYIsFCckORw/viewform"
                >
                  <Button>Join Us</Button>
                </a>
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Nav;
