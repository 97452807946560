import React from "react";
import Marquee from "react-fast-marquee";
// import rising_tide from "../assets/images/rising 1.svg";
import cafe_one from "../assets/images/cafe logo 1.svg";
import homevolt from "../assets/images/HomeVolt  1-02 (2).png";
import sanwo from "../assets/images/SANWOArtboard 1 copy 2@300x.png";
import skiing from "../assets/images/Copy of SKILLNGYELLOW LOGO@300x.png";

const Patners = () => {
  return (
    <div className="bg-[#1A1B23] mt-0 md:mt-[100px] mb-0">
      {" "}
      <div className="spacings text-white items-center py-[30px] md:py-[60px]">
        <h1 className="font-[900] text-[25px] md:text-[35px] lg:text-[50px] tracking-[-1px] leading-[40px] lg:leading-[65px] ">
          Our partners
        </h1>

        <div className=" pt-1 md:pt-8">
          <Marquee>
            <div className="flex gap-[50px] md:gap-[100px] items-center">
              <p>{""}</p>
              {/* <img
                src={rising_tide}
                alt="rising tide"
                className="w-[80px] md:w-full h-[80px] md:h-[100px]"
              /> */}
              <img
                src={cafe_one}
                alt="cafe one"
                className="w-[80px] md:w-full h-[80px] md:h-[100px]"
              />
              <img
                src={homevolt}
                alt="homevolt"
                className="w-[80px] md:w-full h-[80px] md:h-[100px]"
              />
              <img
                src={sanwo}
                alt="sanwo"
                className="w-[80px] md:w-full h-[80px] md:h-[120px]"
              />
              <img
                src={skiing}
                alt="skiing"
                className="w-[80px] md:w-full h-[80px] md:h-[120px]"
              />
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default Patners;
