import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Hero from "../blocks/hero";
import About from "../blocks/about";
import Goals from "../blocks/goals";
import Registration from "../blocks/registration";
import WhatToExpect from "../blocks/whatToExpect";
import Attendance from "../blocks/attendance";
import Pitch from "../blocks/pitch";
import Patners from "../blocks/patners";
import gradient from "../assets/images/bg-gradient-img.svg";
import group from "../assets/images/Group 3.svg";
import img2 from "../assets/images/Group 2 (1).svg";
import img3 from "../assets/images/Group 2 (2).svg";

const Home = () => {
  return (
    <div>
      <div className="bg_hero relative">
        <div className="absolute">
          <img src={gradient} alt="gradient" className="" />
        </div>
        <div className="block md:relative z-[15]">
          <Nav />
        </div>
        <div className="relative z-[15]">
          <Hero />
        </div>
        <div className="absolute right-0 bottom-0 z-[5]">
          <img src={group} alt="group" className="" />
        </div>
      </div>
      <div id="about" className="pt-[150px] md:pt-[200px]">
        <About />
      </div>
      <div id="goals">
        <Goals />
      </div>
      <div className="relative" id="registration">
        <div className="relative z-[15]">
          <Registration />
        </div>
        <div className="absolute left-0 bottom-0 z-[5]">
          <img src={img2} alt="group" className="" />
        </div>
      </div>
      <WhatToExpect />
      <div className="relative">
        <div className="relative z-[15]">
          <Attendance />
        </div>

        <div className="absolute left-0 bottom-0 z-[5]">
          <img src={img3} alt="group" className="" />
        </div>
      </div>

      <Pitch />

      <div id="patners">
        <Patners />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
