import React from "react";
import registration from "../assets/images/img (8).svg";
import Button from "../components/Button";

const Pitch = () => {
  return (
    <div className="spacings text-white flex md:flex-row flex-col gap-8 md:gap-8 items-center pt-[100px] pb-[110px] md:pb-[180px]">
      <div className="w-full md:w-[50%] order-2 md:order-1">
        <h1 className="font-[900] text-[25px] md:text-[35px] lg:text-[50px] leading-[40px] lg:leading-[65px] ">
          Bet on Your Idea!
        </h1>
        <p className="font-[400] text-[#898CA9] text-xs md:text-sm  max-w-[580px] w-full lg:text-base leading-6 md:leading-6 pt-2 md:pt-5">
          Pitch your startup for a shot at equity financing or grants. Limited
          to 10 startups.
        </p>
        <div className="pt-2 md:pt-8">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLScfOdYHFBrazJFzOI1KU6Ygh5zrVNbTE_DCl1muXh7MZBxDag/viewform"
          >
            <Button>Apply to Pitch</Button>
          </a>
        </div>
      </div>
      <div className="w-full md:w-[50%] order-1 md:order-2">
        <img
          src={registration}
          alt="registration"
          className=" rounded-[12px]"
        />
      </div>
    </div>
  );
};

export default Pitch;
