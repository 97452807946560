import React from "react";

const ExpectComp = ({ title, description, img }) => {
  return (
    <div>
      <div className="border-[3.83px] border-[#3D72C2] w-full px-3 py-5 rounded-[7.66px] flex gap-3">
        <img src={img} alt="book" className="" />
        <div className="">
          <p className="font-[500] font-[Outfit] text-white text-base md:text-lg max-w-[401px] w-full lg:text-[24.51px] leading-6 md:leading-6">
            {title}
          </p>
          <p className="font-[500] font-[inter] text-[#7D7D7D] text-sm md:text-base  w-full lg:text-base leading-6 md:leading-6 pt-2 md:pt-3">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExpectComp;
