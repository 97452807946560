import React from "react";
import GoalsComp from "../components/Goals";
import goal from "../assets/icons/img (1).svg";
import goal2 from "../assets/icons/img (3).svg";
import goal3 from "../assets/icons/img (4).svg";
import goal4 from "../assets/icons/img (5).svg";

const Goals = () => {
  return (
    <div className="text-white spacings mt-[120px] md:mt-[50px] mb-[30px] md:mb-[120px]">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <GoalsComp
          title={"Connect Founders"}
          img={goal}
          description={
            "Bridge the gap between entrepreneurs, investors, and mentors."
          }
        />
        <GoalsComp
          title={"Foster Innovation"}
          img={goal2}
          description={"Showcase cutting-edge solutions and industry trends."}
        />

        <GoalsComp
          title={"Facilitate Funding"}
          img={goal3}
          description={"Provide access to equity financing and grants."}
        />

        <GoalsComp
          title={"Empower Growth"}
          img={goal4}
          description={
            "Equip founders with knowledge, networks, and resources."
          }
        />
      </div>
    </div>
  );
};

export default Goals;
