import React from "react";
import registration from "../assets/images/img (6).svg";
import Button from "../components/Button";

const Registration = () => {
  return (
    <div className="spacings text-white flex md:flex-row flex-col gap-8 md:gap-8 items-center pt-[100px] pb-[110px] md:pb-[180px]">
      <div className="w-full md:w-[50%]">
        <img
          src={registration}
          alt="registration"
          className=" rounded-[12px]"
        />
      </div>
      <div className="w-full md:w-[50%]">
        <h1 className="font-[900] text-[25px] md:text-[35px] lg:text-[50px] leading-[40px] lg:leading-[65px] ">
          Introducing Founders Meet Ibadan!
        </h1>
        <p className="font-[400] text-[#898CA9] text-xs md:text-sm max-w-[401px] w-full lg:text-base leading-6 md:leading-6 pt-2 md:pt-3">
          Join us in Ibadan for an unforgettable experience! Network with fellow
          entrepreneurs, learn from industry experts, and pitch your startup for
          funding.
        </p>
        <div className="pt-2 md:pt-3">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLSesC5FhbR3Pt2TrnTKtDH-gRe94uUiDcmuzN5RRYIsFCckORw/viewform"
          >
            <Button>Register Now</Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Registration;
