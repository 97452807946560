import React from "react";

const GoalsComp = ({ title, img, description }) => {
  return (
    <div className="text-white cursor-default">
      <div className="bg-[#3D72C226] flex flex-col items-center gap-5 rounded-[21.47px] py-6 h-full">
        <img src={img} alt="Icon" className="" />
        <p className="font-[800] text-[18px] md:text-[20px] lg:text-[28px] leading-[30px] md:leading-[36px] w-full max-w-[198.77px] text-center">
          {title}
        </p>
        <p className="font-[400] text-[#898CA9] text-xsmd:text-xs lg:text-[13.74px] leading-[20px] md:leading-[23.61px] w-full max-w-[198.77px] text-center">
          {description}
        </p>
      </div>
    </div>
  );
};

export default GoalsComp;
