import React from "react";
import logo from "../assets/images/logo.svg";
import { Mail, Phone } from "@mui/icons-material";

const Footer = () => {
  return (
    <div className="bg-black">
      <div className="py-[50px] md:py-[100px] text-white spacings flex md:flex-row flex-col items-center gap-4">
        <div className="border-r-[none] md:border-r border-r-[#FFFFFF] w-full md:w-1/2  lg:w-[65%] flex flex-col gap-7">
          {" "}
          <div className="">
            <img src={logo} alt="Logo" className="" />
          </div>
          <h1 className="font-[400] text-[#AFAFAF] text-sm md:text-[18px] lg:text-[24px] leading-[19px] ">
            Join the Movement!
          </h1>
          <p className="font-[400] text-xs md:text-sm lg:text-base w-full md:max-w-[300px] lg:max-w-[567px] leading-[19px] text-[#AFAFAF] ">
            Be part of Nigeria's thriving startup ecosystem. Attend Founders
            Meet Ibadan and take your business to the next level!
          </p>
        </div>

        <div className="w-full md:w-1/2  lg:w-[35%] px-0 md:px-20 pt-2 md:pt-0">
          {" "}
          <h1 className="font-[400] text-sm md:text-[18px] lg:text-[24px] leading-[22px] lg:leading-[29.17px] ">
            Contact Us
          </h1>
          <div className="flex gap-3 items-center pt-10">
            <Mail style={{ fontSize: "20px", color: "#AFAFAF" }} />
            <p className="font-[400] text-xs md:text-sm lg:text-base w-full max-w-[567px] leading-[19px] text-[#AFAFAF] ">
              foundersmeet@gmail.com
            </p>
          </div>
          <div className="flex gap-3 items-center pt-4">
            <Phone style={{ fontSize: "20px", color: "#AFAFAF" }} />
            <p className="font-[400] text-xs md:text-sm lg:text-base w-full max-w-[567px] leading-[19px] text-[#AFAFAF] ">
              +234 706 770 1218
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
