import React from "react";
import about from "../assets/images/about.jpg";

const About = () => {
  return (
    <div className="spacings text-white flex md:flex-row flex-col gap-8 md:gap-16 items-center pb-[0px] md:pb-[180px]">
      <div className="w-full md:w-[45%]">
        <img src={about} alt="about" className=" rounded-[12px]" />
      </div>
      <div className="w-full md:w-[65%]">
        <h1 className="font-[900] text-[25px] md:text-[35px] lg:text-[50px] leading-[65px] ">
          About Founders Meet
          <p className="font-[400] text-[#898CA9] text-[16px] md:text-[18px] lg:text-[24px] leading-[30px] md:leading-[36px] pt-1 md:pt-3">
            Founders Meet is a premier startup event connecting entrepreneurs,
            investors, and industry leaders. Our mission is to foster
            collaboration, innovation, and growth in Nigeria's startup
            ecosystem.
          </p>
        </h1>
      </div>
    </div>
  );
};

export default About;
